<template>
  <sub-page
      :title="$t('Structure')"
      :header-action-text="$t('Add')"
      :header-action="openNewStructureEntity"
      :header-action-condition="items.length > 0"
      v-model="$store.state.settings.pageData"
      icon="mdi-sitemap"
      fill-height
      :filters="filtersItems"
  >


    <template #default>

      <div v-if="items.length > 0" class="mt-5">
        <structure-entity
            v-for="(item,index) in itemsFiltered" :key="item.uuid"
            @edit="openEditStructureEntity(item)"
            @edit-employee="editEntityEployee"
            :item="item"
            :index="index"
            :used-single-positions="usedSinglePositions"
            :positions-select="positionsSelect"
            :managers-select="managersSelect"
            non-payed
        />

      </div>

      <div v-else  class="d-flex align-center fill-height justify-center py-10 ">
        <div>
          <div class="d-flex justify-center">
            <v-icon size="60" :color="wsDARKLIGHT">mdi-sitemap</v-icon>
          </div>
          <h4 class="text-center mt-3">
            {{ $t('hr.structure.no_data') }}
          </h4>
          <div class="d-flex justify-center mt-3">
            <ws-button @click="openNewStructureEntity"  label="Add"  />
          </div>
        </div>
      </div>


    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayStructureDialog"
          v-model="displayStructureDialog"
          @save="addEditStructureEntity"
          @delete="deleteStructureEntity"
          :title="$t(newStructureEntity ? 'hr.structure.entity.add' : 'hr.structure.entity.edit')"
          :show-delete="!newStructureEntity"
          :save-text="$t(newStructureEntity ? 'Add' : 'Save')"
      >
        <ws-text-field
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
            v-model="structureEntityData.name"
            :error="structureNameError"
            @input="structureNameError = false"
        />

      </ws-dialog>
    </template>

  </sub-page>
</template>

<script>
import structureEntity from "@/components/university/pages/human_resources/UI/structureEntity";
import {mapActions} from "vuex";

export default {
  name: "hrEmployesAndStructure",
  components : {
    structureEntity
  },
  data() {
    return {
      items : [],
      displayStructureDialog : false,
      structureEntityData : {},
      newStructureEntity : false,
      positionsSelect : [],
      managersSelect : [],

      structureNameError : false
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.COPY(this.items)
      if ( this.$store.state.settings.filtersData.position_id ) {
        items = items.filter(el=> el.employees.filter(el => this.$store.state.settings.filtersData.position_id.includes( el.position_id)  ).length > 0)
      }
      if ( this.$store.state.settings.filtersData.business_user_id ) {
        items = items.filter(el=> el.employees.filter(el => this.$store.state.settings.filtersData.business_user_id.includes( el.business_user_id)  ).length > 0)
      }
      if ( this.$store.state.settings.filtersData.stake_greater ) {
        items = items.filter(el=> el.employees.filter(el => el.stake && el.stake >=  parseFloat(this.$store.state.settings.filtersData.stake_greater)).length > 0 )
      }

      if ( this.$store.state.settings.filtersData.stake_less ) {
        items = items.filter(el=> el.employees.filter(el => el.stake && el.stake <=  parseFloat(this.$store.state.settings.filtersData.stake_less)).length > 0 )
      }

      if ( this.$store.state.settings.filtersData.stake_type || this.$store.state.settings.filtersData.stake_type === false ) {
        if ( this.$store.state.settings.filtersData.stake_type === 'no_stake' ) {
          items = items.filter(el=> el.employees.filter(el => !el.stake  ).length > 0);
        }
        if ( this.$store.state.settings.filtersData.stake_type === true ) {
          items = items.filter(el=> el.employees.filter(el => el.is_main  ).length > 0);
        }
        if ( this.$store.state.settings.filtersData.stake_type === false ) {
          items = items.filter(el=> el.employees.filter(el => !el.is_main && el.stake  ).length > 0);
        }
      }

      return items
    },
    filtersItems() {
      return [
          {text : this.$t('Position') , value : 'position_id' , type : 'select_multiple' , items : this.positionsSelect},
          {text : this.$t('Employee') , value : 'business_user_id' , type : 'select_multiple' , items : this.managersSelect},
          {text : this.$t('hr.employee.is_main.title') , value : 'stake_type' , type : 'select' , items : this.stakeTypeSelect},
          {text : this.$t('hr.employee.filters.stake_greater') , value : 'stake_greater' , type : 'text_number'   },
          {text : this.$t('hr.employee.filters.stake_less') , value : 'stake_less' , type : 'text_number'   }

      ]
    },
    stakeTypeSelect() {
      return [
        { text : this.$t('hr.employee.is_main.true') , value : true } ,
        { text : this.$t('hr.employee.is_main.false') , value : false },
        { text : this.$t('hr.employee.no_stake') , value : 'no_stake' }
      ]

    },
    totalSalary() {
      let array = this.items.map(el => el.employees)
      let items = []
      array.forEach(el => {
        items = [...items , ...el]
      })

      return items.map(el => el.salary).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    usedSalary() {
      let array = this.items.map(el => el.employees)
      let items = []
      array.forEach(el => {
        items = [...items , ...el]
      })

      return items.filter(el=>
          !!el.business_user_id
          && ((this.nowTimestamp >= el.date_start) || !el.date_start)
          && ((this.nowTimestamp <= el.date_end) || !el.date_end)
      )
          .map(el => el.salary).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },


    usedSinglePositions() {
      let array = this.items.map(el => el.employees)
      let items = []
      array.forEach(el => {
        items = [...items , ...el]
      })
      return [... new Set(items.filter(el => el.position_is_single).map(el => el.position_id)) ]
    }
  },
  methods : {
    ...mapActions('hr', [
      'GET_HR_STRUCTURE',
      'ADD_EDIT_HR_STRUCTURE_ENTITY',
      'DELETE_HR_STRUCTURE_ENTITY'
    ]),

    async addEditStructureEntity() {
      if ( !this.structureEntityData.is_non_payed ) {
        this.structureEntityData.is_non_payed = true
      }
      if ( !this.structureEntityData.name ) {
        this.structureNameError = true
        return this.WARNING('EnterName')
      }
      let result = await this.ADD_EDIT_HR_STRUCTURE_ENTITY(this.structureEntityData)
      if ( !result ) {
        return this.ERROR()
      }
      if ( this.newStructureEntity ) {
        this.items.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        this.items[index] = result
        this.items = this.COPY(this.items)
      }

      this.displayStructureDialog = false
    },
    async deleteStructureEntity() {
      let result = await this.DELETE_HR_STRUCTURE_ENTITY(this.structureEntityData.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.items.findIndex(el => el.uuid === this.structureEntityData.uuid)
      if ( index === -1 ) {
        this.ERROR()
        return
      }
      this.items.splice(index , 1)

      this.displayStructureDialog = false
    },

    openNewStructureEntity() {
      this.newStructureEntity = true
      this.structureEntityData = {}
      this.displayStructureDialog = true
    },
    openEditStructureEntity(item) {
      this.newStructureEntity = false
      this.structureEntityData = this.COPY(item)
      this.displayStructureDialog = true
    },

    editEntityEployee({ item, isNew }) {
      let index = this.items.findIndex(el => el.uuid === item.structure_entity_id)
      if ( index === -1 )  {
        return
      }
      if (isNew) {
        this.items[index].employees.push(item)
      } else {
        let employeeIndex = this.items[index].employees.findIndex(el => el.uuid === item.uuid)
        if ( index === -1 )  {
          return
        }
        this.items[index].employees[employeeIndex] = item
      }
      this.items = this.COPY(this.items)
    },

    async initPage() {
      let result = await this.GET_HR_STRUCTURE()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items.filter(el => el.is_non_payed)
      this.positionsSelect = result.positions_select
      this.managersSelect = result.managers_select
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>