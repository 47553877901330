<template>
  <div>
    <v-hover #default="{hover}">
      <v-sheet :style="`border : 1px solid ${wsBACKGROUND};` + (index !== 0 ? 'border-top:none' : '') " >

        <div class="d-flex align-center justify-space-between pa-2 px-3 pointer">
          <div class="d-flex align-center">
            <v-btn @click="expanded = !expanded" icon>
              <v-icon :color="wsACCENT">mdi-menu-{{  expanded ? 'down' : 'right'  }}</v-icon>
            </v-btn>
            <h4 @click="expanded = !expanded" :style="`color : ${wsACCENT}`">{{ item.name }}</h4>
            <h5 v-if="!nonPayed" class="ml-2">
              <span v-if="usedSalary !== totalSalary">({{usedSalary}}/{{ totalSalary }})</span>
              <span v-else>{{ usedSalary }}</span>
              {{ BUSINESS_CURRENCY }}
            </h5>
          </div>

          <v-btn @click="$emit('edit')" v-if="hover" icon>
            <v-icon :color="wsACCENT">mdi-pencil</v-icon>
          </v-btn>
        </div>

        <!-- Expanded Items-->

        <v-expand-transition>
          <div v-if="expanded">
            <v-divider></v-divider>
            <div v-if="item.employees.length > 0" >
              <ws-data-table
                  :items="itemsFiltered"
                  :headers="headers"
                  :row-action="($event) => openEditEmployee($event)"
                  no-header
                  no-footer
                  disable-pagination
              >
                <template #item.business_user_name="{item}">
                  <div class="text-no-wrap ml-8 my-3">
                    <ws-user-data v-if="item.business_user_name" :item="{name : item.business_user_name , img : item.business_user_img , email : item.business_user_email}" />

                    <ws-button
                        v-else
                        :color="wsDARKLIGHT"
                        label="hr.employee.choose"
                        outlined
                        :click-stop="false"
                    />

                  </div>
                </template>

                <template #item.is_main="{item}">
                  <div>
                    <h5 v-if="!item.is_main && item.stake" class="mr-8 text-no-wrap">
                      {{  $t('hr.employee.is_main.false') }}
                    </h5>
                  </div>

                </template>

                <template #item.date_start="{item}">
                  <h5 class="text-no-wrap" v-if="item.date_start">{{ MONTH_DAY_YEAR(item.date_start) }}</h5>
                </template>
                <template #item.date_end="{item}">
                  <h5 class="text-no-wrap" >
                    {{ item.date_end ? MONTH_DAY_YEAR(item.date_end) : $t('hr.employee.date_end.placeholder') }}
                  </h5>
                </template>
                <template #item.salary="{item}">
                  <div>
                    <h5 class="text-no-wrap" v-if="item.salary">{{ item.salary }} {{ BUSINESS_CURRENCY }}</h5>
                  </div>

                </template>
                <template #item.stake="{item}">
                  <h5  class="mr-8 text-no-wrap"> {{ item.stake ?  `${$t('hr.employee.stake.title')} : ${item.stake}` : $t('hr.employee.no_stake') }}</h5>
                </template>

                <template #item.position_name="{item}">
                  <h5 :style="`color : ${wsACCENT}`" class="mr-8 text-no-wrap"> {{ item.position_name }}</h5>
                </template>



              </ws-data-table>
              <ws-button @click="openNewEmployee(item.uuid)" block text label="hr.employee.add" />
            </div>

            <div v-else  class="d-flex align-center justify-center py-10 ">
              <div>
                <div class="d-flex justify-center">
                  <v-icon size="60" :color="wsDARKLIGHT">mdi-account-tie</v-icon>
                </div>
                <h4 class="text-center mt-3">
                  {{ $t('hr.structure.entity.no_employees_text_non_payed') }}
                </h4>
                <div class="d-flex justify-center mt-3">
                  <ws-button @click="openNewEmployee(item.uuid)"  label="hr.employee.add_non_payed"  />
                </div>
              </div>
            </div>


          </div>
        </v-expand-transition>

      </v-sheet>
    </v-hover>

    <!-- Dialogs-->
    <ws-dialog
        v-if="displayDialog"
        v-model="displayDialog"
        @save="addEditEmployee"
        :title="$t('hr.employee.add')"
    >
      <ws-select
          class="mt-5"
          v-model="entityData.position_id"
          :label="$t('Position')"
          :placeholder="$t('hr.positions.choose')"
          :items="positionsFiltered"
          :error="positionError"
          @input="positionError = false"
          autocomplete
      />

      <ws-select
          class="mt-5"
          v-model="entityData.business_user_id"
          :label="$t('Employee')"
          :placeholder="$t('hr.employee.choose')"
          :items="managersSelect"
          autocomplete
      >
        <template #item="{item}">
          <v-sheet width="100%" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`"  height="60">
            <ws-user-data  :item="{name : item.text , img : item.img , email : item.email}" />
          </v-sheet>
        </template>

      </ws-select>


      <v-row no-gutters>
        <v-col cols="6" class="pr-1">
          <ws-date-picker
              class="mt-5"
              v-model="entityData.date_start"
              :label="$t('hr.employee.date_start.title')"
              :placeholder="$t('ChooseDate')"
              clearable
              time-stamp
              key="start"
          />
        </v-col>
        <v-col cols="6" class="pl-1">
          <ws-date-picker
              class="mt-5"
              v-model="entityData.date_end"
              :label="$t('hr.employee.date_end.title')"
              :placeholder="$t('hr.employee.date_end.placeholder')"
              clearable
              time-stamp
              key="end"
          />
        </v-col>
      </v-row>

      <v-row v-if="!nonPayed" no-gutters class="pt-3">
        <v-col cols="6" class="pr-1">
          <ws-select
              v-model="entityData.is_main"
              :label="$t('hr.employee.is_main.title')"
              :placeholder="$t('hr.employee.is_main.placeholder')"
              :items="isMainSelect"
              autocomplete
          />
        </v-col>
        <v-col v-if="entityData.is_main !== 'no_stake'" cols="6" class="pl-1">
          <ws-text-field
              v-model="entityData.stake"
              :label="$t('hr.employee.stake.size')"
              placeholder="0"
              number
              clearable
          />
        </v-col>
      </v-row>


    </ws-dialog>
  </div>



</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "structureEntity",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array,
      default() { return [] }
    },
    positionsSelect : {
      type : Array,
      default() { return [] }
    },
    managersSelect : {
      type : Array,
      default() { return [] }
    },
    usedSinglePositions : {
      type : Array,
      default() { return [] }
    },
    index : {
      type : Number,
      default : 0
    },
    nonPayed : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      expanded : false,
      newEntity : false,
      entityData : {},
      displayDialog : false,

      positionError : false
    }
  },
  computed :  {

    itemsFiltered() {
      let items = this.item.employees
      if ( this.$store.state.settings.filtersData.position_id  ) {
        items = items.filter( el => this.$store.state.settings.filtersData.position_id.includes( el.position_id) )
      }
      if ( this.$store.state.settings.filtersData.business_user_id  ) {
        items = items.filter( el => this.$store.state.settings.filtersData.business_user_id.includes( el.business_user_id) )
      }

      if ( this.$store.state.settings.filtersData.stake_greater  ) {
        items = items.filter( el => el.stake && el.stake >=  this.$store.state.settings.filtersData.stake_greater )
      }

      if ( this.$store.state.settings.filtersData.stake_less  ) {
        items = items.filter( el => el.stake && el.stake <=  this.$store.state.settings.filtersData.stake_less )
      }

      if ( this.$store.state.settings.filtersData.stake_type || this.$store.state.settings.filtersData.stake_type === false ) {
        if ( this.$store.state.settings.filtersData.stake_type === 'no_stake' ) {
          items = items.filter(el => !el.stake && !el.is_main );
        }
        else if ( this.$store.state.settings.filtersData.stake_type === true ) {
          items = items.filter(el => el.is_main );
        }

        else if ( this.$store.state.settings.filtersData.stake_type === false ) {
          items = items.filter(el => !el.is_main && el.stake > 0 );
        }
      }


      return items
    },

    positionsFiltered() {
      let items = this.COPY(this.positionsSelect)
      return items.filter(el => !this.usedSinglePositions.includes(el.value) || this.entityData.position_id === el.value)
    },
    selectedPositionHasSalary() {
      if ( !this.entityData.position_id) {
        return true
      }
      let selectedPosition = this.positionsSelect.find(el => el.value === this.entityData.position_id)
      if ( selectedPosition ) {
        return selectedPosition.has_salary
      }
      return true
    },
    isMainSelect() {
      if ( this.selectedPositionHasSalary ) {
        return [
          { text : this.$t('hr.employee.is_main.true') , value : true } ,
          { text : this.$t('hr.employee.is_main.false') , value : false },
          { text : this.$t('hr.employee.no_stake') , value : 'no_stake' }
        ]
      } else {
        return [
          { text : this.$t('hr.employee.no_stake') , value : 'no_stake' }
        ]
      }

    },
    headers() {
      return [
        { text : this.$t('Employee') , value : 'business_user_name' },
        { text : this.$t('Name') , value : 'is_main' , width : 10 },
        { text : this.$t('DateStart') , value : 'date_start' , width : 10 },
        { text : this.$t('DateEnd') , value : 'date_end' , width : 10 },
        { text : this.$t('Stake') , value : 'stake' , width : 10 },
        { text : this.$t('Salary') , value : 'salary' , width : 10 },
        { text : this.$t('Name') , value : 'position_name' , width : 10 },

      ]
    },
    totalSalary() {
      return this.itemsFiltered.map(el => el.salary).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    usedSalary() {
      return this.itemsFiltered.filter(el=>
          !!el.business_user_id
          && ((this.nowTimestamp >= el.date_start) || !el.date_start)
          && ((this.nowTimestamp <= el.date_end) || !el.date_end)
      )
          .map(el => el.salary).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    nowTimestamp() {
      let date = (new Date()).getTime()
      return date/1000
    },
  },
  watch : {
    selectedPositionHasSalary(value) {
      if ( !value ) {
        this.entityData.is_main = 'no_stake'
        this.entityData.stake = null
      }
    }
  },
  methods : {
    ...mapActions('hr', [
      'ADD_EDIT_HR_EMPLOYEE',
      'DELETE_HR_EMPLOYEE'
    ]),

    async addEditEmployee() {

      if ( !this.entityData.position_id ) {
        this.positionError = true
        return this.WARNING('hr.positions.choose')
      }

      this.entityData.structure_entity_id = this.item.uuid
      if ( !this.entityData.stake && !this.entityData.is_main ) {
        this.entityData.is_main = 'no_stake'
      }
      let result = await this.ADD_EDIT_HR_EMPLOYEE(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      let emitData = {
        item : result,
        isNew : this.newEntity
      }
      this.$emit('edit-employee' , emitData)

      this.displayDialog = false
    },
    async deleteEmployee() {
      let result = await this.DELETE_HR_EMPLOYEE(this.entityData.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.items.findIndex(el => el.uuid === this.entityData.uuid)
      if ( index === -1 ) {
        this.ERROR()
        return
      }
      this.items.splice(index , 1)

      this.displayDialog = false
    },

    openNewEmployee() {
      this.newEntity = true
      this.entityData = {
        is_main : true
      }
      this.displayDialog = true
    },
    openEditEmployee(item) {
      this.newEntity = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },
  }
}
</script>

<style scoped>

</style>